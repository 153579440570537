// 验证手机号
export function inspectPhone(val) {
  let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
  return myreg.test(val)
}

// 验证非负浮点数
export function inspectFloat(val) {
  let myreg = /^\d+(\.\d+)?$/
  return myreg.test(val)
}
